<template>
  <b-container fluid class="le-container p-0">
    <div v-for="(expression, key) in expressions" :key="getID(key + '-' + uuidv4())" :class="`operator-${expression.logical_operator}`">
      <b-row>
        <b-col cols="12" class="d-flex align-items-center mt-1 mb-1" v-if="key > 0">
          <hr class="w-100" />

          <span class="d-inline-block px-1" style="flex: none;">
            <b-button
              size="sm"
              :variant="expression.logical_operator == 1 ? 'outline-primary' : 'outline-secondary'"
              @click="setLogicalOperator(key, 1)"
            >
              AND
            </b-button>
          </span>
          <span class="d-inline-block px-25" style="flex: none;"> | </span>
          <span class="d-inline-block px-1" style="flex: none;">
            <b-button 
              size="sm"
              :variant="expression.logical_operator == 2 ? 'outline-primary' : 'outline-secondary'"
              @click="setLogicalOperator(key, 2)"
            >
              OR
            </b-button>
          </span>

          <hr class="w-100" />
        </b-col>
      </b-row>
      <b-row v-if="expression.comparison.fields_configuration == 2">
        <b-col cols="12" md="4">
          <div class="text-muted font-weight-bolder small text-uppercase">First Value</div>
          <custom-input
            id="custom-input"
            v-model="expressions[key].first_value"
            :possibleValues="variables"
            :advanced="false"
            
          />
        </b-col>
        <b-col cols="12" md="3">
          <div class="text-muted font-weight-bolder small text-uppercase">Comparison</div>
          <v-select
            id="custom-select" 
            v-model="expressions[key].comparison"
            :options="logicalConnectors"
            label="name"
            class="w-100"
          >
            <template #option="{ name }">
              <span>{{ name }}</span>
            </template>
          </v-select>
        </b-col>
        <b-col cols="12" md="4">
          <div class="text-muted font-weight-bolder small text-uppercase">Second Value</div>
          <custom-input
            id="custom-input-2"
            v-model="expressions[key].second_value"
            :possibleValues="variables"
            :advanced="false"
          />
        </b-col>
        <b-col cols="12" md="1">
          <b-button variant="outline-danger" class="mt-2" size="sm" @click="deleteItem(key)">
            <feather-icon
              icon="XIcon"
            />
          </b-button>
        </b-col>
      </b-row>
      <b-row v-else-if="expression.comparison.fields_configuration == 1">
        <b-col cols="12" md="8">
          <div class="text-muted font-weight-bolder small text-uppercase">Value</div>
          <custom-input 
            id="custom-input-3" 
            v-model="expressions[key].first_value"
            :possibleValues="variables"
            :advanced="false"
            />
        </b-col>
        <b-col cols="12" md="3">
          <div class="text-muted font-weight-bolder small text-uppercase">Validation</div>
            <v-select
              id="custom-select"
              v-model="expressions[key].comparison"
              :options="logicalConnectors"
              label="name"
            >
              <template #option="{ name }">
                <span>{{ name }}</span>
              </template>
            </v-select>
        </b-col>
        <b-col cols="12" md="1">
          <b-button variant="outline-danger" class="mt-2" size="sm" @click="deleteItem(key)">
            <feather-icon
              icon="XIcon"
            />
          </b-button>
        </b-col>
      </b-row>
      <b-row v-else-if="expression.comparison.fields_configuration == 3">
        <b-col cols="12" md="6">
          <div class="text-muted font-weight-bolder small text-uppercase">Value</div>
          <custom-input 
            id="custom-input-3"
            v-model="expressions[key].first_value"
            :possibleValues="variables"
            :advanced="false"
          />
        </b-col>
        <b-col cols="12" md="5">
          <div class="text-muted font-weight-bolder small text-uppercase">Operation</div>
            <v-select
              id="custom-select"
              v-model="expressions[key].comparison"
              :options="logicalConnectors"
              label="name"
            >
              <template #option="{ name }">
                <span>{{ name }}</span>
              </template>
            </v-select>
        </b-col>
        <b-col cols="12" md="1">
          <b-button variant="outline-danger" class="mt-1" @click="deleteItem(key)">
            <feather-icon
              icon="XIcon"
            />
          </b-button>
        </b-col>
        <b-col cols="12" class="mt-50">
          <div class="text-muted font-weight-bolder small text-uppercase">LIST OF VALUES</div>
          <b-form-tags
            tag-variant="light-info"
            v-model="expressions[key].values"
            input-id="tags-basic"
            class="mb-2"

            tag-class="font-weight-bolder font-size-12"
          />
        </b-col>
      </b-row>
    </div>
    <div>
      <b-button class="px-75 py-50" variant="outline-primary" @click="addItem"><feather-icon icon="PlusIcon"/> Add Comparison</b-button>
    </div>
  </b-container>
</template>

<script>
import { BRow, BCol, BContainer, BButton, BFormTags, } from "bootstrap-vue";
import CustomInput from "../CustomInput.vue";
import VSelect from "vue-select";
import Input from './Input.js';
import LogicalComparison from "./LogicalComparison.js";
import { v4 as uuidv4 } from "uuid";

export default {
  components: {
    BRow,
    BCol,
    BContainer,
    CustomInput,
    BButton,
    VSelect,
    BFormTags,
  },
  props: { 
    variables: {
      type: Object,
      default: () => {}
    },
    value: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      expression: {},
      logicalConnectors: new LogicalComparison().items,
      expressions: [],
      uuidMap: {},
      uuidv4,
    }
  },
  computed: {
    field: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
  },
  mounted() {
    this.init();
  },
  watch: {
    expressions: {
      handler() {
        this.buildScript();
      },
      deep: true
    }
  },
  methods: {
    init() {
      //* normalize agent info
      this.buildExpressions();

      //* after processing agent info
      // this.addItem();
    },
    buildExpressions() {
      if (!this.field) {
        return
      }

      let json = JSON.parse(this.field);
      this.expressions = [];

      if(json['$OR'] === undefined) {
        return;
      }

      json['$OR'].forEach((el) => {
        el['$AND'].forEach((_el, idx) => {
          let op = 2;
          if(idx) {
            op = 1;
          }

          Object.keys(_el).forEach((key) => {
            let cmp = (new LogicalComparison()).getFromValue(key);

            let values = [];
            if(cmp.fields_configuration === 3) {
              values = _el[key].splice(1);
            }

            this.expressions.push({
              first_value: `${_el[key][0]}`,
              second_value: `${_el[key][1]}` || undefined,
              comparison: cmp,
              logical_operator: op,
              values: values,
            });
          });
        });
      });
    },
    addItem() {
      this.expressions.push(new Input())
    },
    deleteItem(key) {
      this.expressions.splice(key, 1)
    },
    setLogicalOperator(key, operatorType) {
      this.$set(this.expressions[key], 'logical_operator', operatorType)
    },
    buildScript() {
      let script = { '$OR': [{ '$AND': [] }] };

      const operator = {
        1: '$AND',
        2: '$OR',
      };

      let current = script['$OR'][0]['$AND'];
      let parent = script['$OR'];

      let floatRegex = /^-?\d+(?:[.,]\d*?)?$/;
      let intRegex = /^\d+$/;
      
      this.expressions.forEach((el, index) => {
        if(el.logical_operator === 2 && index > 0) {
          let obj = {};
          obj[operator[1]] = [];
          let idx = parent.push(obj) - 1;
          current = parent[idx][operator[1]]
        }

        let obj = {};

        let value = el.first_value;
        // if(floatRegex.test(value)) {
        //   value = parseFloat(value);
        // } else if(intRegex.test(value)) {
        //   value = parseInt(value);
        // }

        obj[el.comparison.value] = [];

        obj[el.comparison.value].push(value);

        if(el.comparison.fields_configuration === 2) {
          value = el.second_value;
          // if(floatRegex.test(value)) {
          //   value = parseFloat(value);
          // } else if(intRegex.test(value)) {
          //   value = parseInt(value);
          // }

          obj[el.comparison.value].push(value);
        }
        
        if(el.comparison.fields_configuration === 3) {
          obj[el.comparison.value] = JSON.parse(JSON.stringify(el.values));
          obj[el.comparison.value].unshift(el.first_value);
        }

        current.push(obj);
      });

      this.field = JSON.stringify(script);
    },
    getID(key) {
      if (this.uuidMap[key]) {
        return this.uuidMap[key];
      }

      const uuid = uuidv4();
      this.uuidMap[key] = uuid;

      return uuid;
    },
  }
};
</script>

<style lang="scss">
.le-container {
  min-width: 500px;
}

custom-select {
  min-width: 500px;
}

.vs__selected-options {
  flex-wrap: nowrap !important;
}
.font-size-12 {
  font-size: 12px !important;
}
</style>