export default class LogicanComparison {
  constructor() {
    this.items = [
      { 
        id: 1, 
        name: "LOGICAL AND", 
        value: "$AND", 
        fields_configuration: 2, 
        symbol: "&&"
      },
      { 
        id: 2, 
        name: "LOGICAL OR", 
        value: "$OR", 
        fields_configuration: 2, 
        symbol: "||"
      },
      { 
        id: 3, 
        name: "LOGICAL XOR", 
        value: "$XOR", 
        fields_configuration: 2 , 
        symbol: "XOR"
      },
      { 
        id: 4,
        name: "LOGICAL NOT", 
        value: "$NOT", 
        fields_configuration: 1 , 
        symbol: "NOT"
      },
      { 
        id: 5, 
        name: "EQUAL", 
        value: "$EQ", 
        fields_configuration: 2, 
        symbol: "=="
      },
      { 
        id: 6, 
        name: "NOT EQUAL", 
        value: "$NE", 
        fields_configuration: 2, 
        symbol: "!="
      },
      { 
        id: 7, 
        name: "LESS THAN", 
        value: "$LT", 
        fields_configuration: 2, 
        symbol: "<"
      },
      { 
        id: 8, 
        name: "LESS/EQUAL", 
        value: "$LTE", 
        fields_configuration: 2, 
        symbol: "≤"
      },
      { 
        id: 9, 
        name: "GREATER", 
        value: "$GT", 
        fields_configuration: 2, 
        symbol: ">"
      },
      { 
        id: 10, 
        name: "GREATER/EQUAL", 
        value: "$GTE", 
        fields_configuration: 2, 
        symbol: "≥"
      },
      { 
        id: 11, 
        name: "IN THE LIST", 
        value: "$IN", 
        fields_configuration: 3, 
        symbol: "IN LIST"
      },
      { 
        id: 12, 
        name: "NOT IN THE LIST", 
        value: "$NIN", 
        fields_configuration: 3, 
        symbol: "NOT IN LIST"
      },
    ];
  }

  getFromValue(value) {
    const map = {
      '$AND': 1,
      '$OR': 2,
      '$XOR': 3,
      '$NOT': 4,
      '$EQ': 5,
      '$NE': 6,
      '$LT': 7,
      '$LTE': 8,
      '$GT': 9,
      '$GTE': 10,
      '$IN': 11,
      '$NIN': 12,
    };

    return this.items[map[value] - 1];
  }
}
