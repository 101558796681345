export default class Input {
  constructor(
    field_configuration = { id: 10, name: "GREATER/EQUAL", value: "$GTE", fields_configuration: 2, },
    first_value = '',
    second_value = '',
    logical_operator = 1,
    values = [],
  ){
    this.first_value = first_value 
    this.comparison = field_configuration
    this.second_value = second_value 
    this.logical_operator = logical_operator
    this.values = values
  }
}